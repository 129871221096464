import "./Education.scss";

export default function Education() {
  return (
    <div className="education" id="Education">
      <h1 className="header">Education</h1>
      <br />
      <br />
      <div className="school" id="rutgers">
        <img
          className="school_logo"
          src="assets/company_logos/rutgers_logo.png"
          alt=""
        />
       <br />
          <h2>Rutgers University</h2>
          <h3 className="info">B. S. • Computer Science • 2020-2023</h3>
          <h3 className="info">Magna Cum Laude</h3>
          <h3 className="info">GPA: 3.7/4.0</h3>
      </div>
    </div>
  );
}
