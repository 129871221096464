import React, { useState, useEffect, useCallback } from "react";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import "./Slider.scss";

export const Slider = ({ data }) => {
  const [slide, setSlide] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const manualNextSlide = useCallback(() => {
    setSlide((prevSlide) =>
      prevSlide === data.length - 1 ? 0 : prevSlide + 1
    );
  }, [data.length]);

  const autoNextSlide = useCallback(() => {
    if (!isHovering) {
      setSlide((prevSlide) =>
        prevSlide === data.length - 1 ? 0 : prevSlide + 1
      );
    }
  }, [data.length, isHovering]);

  const prevSlide = useCallback(() => {
    setSlide((prevSlide) =>
      prevSlide === 0 ? data.length - 1 : prevSlide - 1
    );
  }, [data.length]);

  useEffect(() => {
    const interval = setInterval(autoNextSlide, 6000); // Use autoNextSlide here
    return () => clearInterval(interval);
  }, [autoNextSlide]);

  return (
    <div className="slider">
      <BsArrowLeftCircleFill className="arrow arrow-left" onClick={prevSlide} />
      {data.map((item, idx) => {
        return (
          <div
            key={idx}
            className={slide === idx ? "slide" : "slide slide-hidden"}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <img className="company_logo" src={item.src} alt={item.alt} />
            <div className="company_info">
              <h2>{item.school}</h2>
              <h3 className="info">{item.location}</h3>
              <h3 className="position">{item.position}</h3>
              <br />
              <h3 className="desc">{item.desc}</h3>
            </div>
          </div>
        );
      })}
      <BsArrowRightCircleFill
        className="arrow arrow-right"
        onClick={manualNextSlide}
      />{" "}
      {/* Use manualNextSlide here */}
      <span className="indicators">
        {data.map((_, idx) => {
          return (
            <button
              key={idx}
              onClick={() => setSlide(idx)}
              className={
                slide === idx ? "indicator" : "indicator indicator-inactive"
              }
            ></button>
          );
        })}
      </span>
    </div>
  );
};

export default Slider;
