import "./extracurriculars.scss";

import Slider from "./Slider/Slider";

import sliderData from "./Slider/sliderData.json";

export default function Extracurriculars() {
  return (
    <div className="extracurics" id="extracurics">
      <h1 className="header">Extra Curriculars</h1>
      <Slider data={sliderData.slides} />
    </div>
  );
}
