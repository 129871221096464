import "./portfolio.scss";

import Slider from "./Slider/Slider";

import sliderData from "./Slider/sliderData.json";

export default function Portfolio() {
  return (
    <div className="portfolio" id="portfolio">
      <h1 className="header">Experiences</h1>
      <Slider data={sliderData.slides} />
    </div>
  );
}
